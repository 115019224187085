import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import * as ManualSearchActions from '../../../Actions/ManualSearchActions';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import { getConfig, getConfiguredSources, getPortalsConfig } from '../../../Reducers/ConfigReducer';
import { getCurrentJobDetails } from '../../../Reducers/JobReducer';
import { getManualSearchCriteria } from '../../../Reducers/ManualSearchReducer';
import { generateManualSearchPayload, getInitialSources } from '../../../Utils/ManualSearchUtils';
import { getSourceName } from '../../../Utils/SourceUtils';
import { getFilteredSources } from '../../ManualSearchV2/ManualSearchForm/ManualSearchForm';
import ManualSearchContext from '../../ManualSearchV2/ManualSearchWrapper/ManualSearchContext';
import RefreshSmartAgentCandidates from './RefreshSmartAgentCandidates';

const mapStateToProps = (state, props) => ({
  sources: getConfiguredSources(state),
  currentJobDetails: getCurrentJobDetails(state),
  userConfig: getConfig(state),
  unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
  portalsConfig: getPortalsConfig(state),
  saveSmartAgentSearchCriteriaApiStatus: getApiStatus(state, 'saveSmartAgentSearchCriteriaApiStatus'),
});

const mapDispatchToProps = {
  saveSmartAgentCriteria: ManualSearchActions.saveSmartAgentCriteria,
};

function SmartAgentCandidatesFetchButton(props) {
  const {
    saveSmartAgentCriteria,
    jobId,
    sources,
    currentJobDetails,
    candidateContext,
    featureToggleList,
    userConfig,
    unsavedCriteria,
    portalsConfig,
    saveSmartAgentSearchCriteriaApiStatus,
    sourceName,
  } = props;

  const { form: manualSearchForm } = React.useContext(ManualSearchContext);

  if (!currentJobDetails) return null;

  const manualSearchFormValues = manualSearchForm.getFieldsValue();

  const manualSearchPayload = generateManualSearchPayload({
    formValues: manualSearchFormValues,
    locationType: manualSearchFormValues.LocationType,
    activeSearchString: manualSearchFormValues.ActiveSearchString,
    isAdvancedSearchV2Enabled: true,
  });

  const criteria = {
    JobId: jobId,
    IsDraft: true,
    Criteria: { ...manualSearchPayload },
  };
  const sourceNamesSelected = manualSearchForm.getFieldValue('sourceNamesSelected') || [];
  const isNonInternalPortalForSegmentEnabled = featureToggleList?.PrefetchAdvanceSearchResults?.IsEnabled;
  const isAllPortalsAllowed = candidateContext === 'job' || isNonInternalPortalForSegmentEnabled;
  const defaultSources = unsavedCriteria.Sources || [];
  const _sourceNamesSelected = sourceNamesSelected?.length
    ? sourceNamesSelected
    : getInitialSources({
        jobCountryCode: currentJobDetails.CountryCode,
        userConfig,
        sources:
          defaultSources?.length && isAllPortalsAllowed
            ? defaultSources
            : getFilteredSources({
                candidateContext,
                isNonInternalPortalForSegmentEnabled,
                sources,
              }) || [],
        portalsConfig,
        allowActiveChannel: true,
      });

  const selectedSources = (_sourceNamesSelected || [])
    .map(x => sources.find(y => getSourceName(y) === x))
    .filter(x => !!x);
  const sourcesPayload = selectedSources.map(x => ({
    ...x,
    Name: x?.Portal === 'Internal' && !userConfig.ShowVaultName ? undefined : x.Name,
  }));
  criteria.Criteria = { ...manualSearchPayload, Sources: sourcesPayload };

  const onClick = () => {
    saveSmartAgentCriteria(criteria);
  };

  const isReactivateAgentButtonLoading = saveSmartAgentSearchCriteriaApiStatus === 'INPROGRESS';

  return sourceName !== 'smartAgentCandidates' ? (
    <div>
      <Button onClick={onClick} loading={isReactivateAgentButtonLoading}>
        Reactivate agent
      </Button>
    </div>
  ) : (
    <RefreshSmartAgentCandidates jobId={jobId} sourcesPayload={sourcesPayload} />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartAgentCandidatesFetchButton);
