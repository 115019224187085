import React from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import GlobalInsightSupplyMenuList from '../GlobalInsight/Filter/GlobalInsightSupplyMenuList';
import GlobalInsightList from '../GlobalInsight/InsightList/GlobalInsightList';
import { menuList } from '../../Utils/GlobalInsightSupplyUtil';

export const defaultExperinceRanges = [
  {
    To: 2,
  },
  {
    From: 2,
    To: 5,
  },
  {
    From: 5,
    To: 11,
  },
  {
    From: 11,
    To: 15,
  },
  {
    From: 15,
  },
];

export function getExperienceRange(minExperience, maxExperience) {
  return minExperience || maxExperience ? [{ From: minExperience, To: maxExperience }] : defaultExperinceRanges;
}

function getTitleSynonyms({ currentJobDetails, aryaGeneratedCriteria, unsavedCriteria }) {
  if (!_.isEmpty(unsavedCriteria)) {
    return unsavedCriteria.Titles.map(x => x.Title);
  }

  if (!_.isEmpty(aryaGeneratedCriteria)) {
    return aryaGeneratedCriteria.Titles.map(x => x.Title);
  }

  return currentJobDetails.Synonyms?.filter(synonym => synonym?.trim().length > 0);
}

export const getMarketIntelPayload = ({ currentJobDetails, aryaGeneratedCriteria, unsavedCriteria }) => {
  const location = currentJobDetails?.Location?.split(',');
  const titleSynonyms = getTitleSynonyms({ currentJobDetails, aryaGeneratedCriteria, unsavedCriteria });
  const payload = {
    Title: currentJobDetails.JobTitle,
    CountryCode: currentJobDetails.CountryCode,
    State: location?.[1]?.trim(),
    City: location?.[0]?.trim(),
    Industries: currentJobDetails.Industries?.filter(industry => industry?.trim().length > 0),
    TitleSynonyms: titleSynonyms,
  };
  if (location) {
    payload.Distance = 50;
  }
  return payload;
};

export default function MarketIntelligence({
  marketIntel,
  loading,
  currentJobDetails,
  aryaGeneratedCriteria,
  unsavedCriteria,
  fetchGlobalInsightSupplyList,
}) {
  const [supplyPayload, setSupplyPayload] = React.useState({});

  React.useEffect(() => {
    const marketJobIntelPayload = getMarketIntelPayload({ currentJobDetails, aryaGeneratedCriteria, unsavedCriteria });
    fetchGlobalInsightSupplyList(marketJobIntelPayload);
    setSupplyPayload(marketJobIntelPayload);
    return () => {
      console.log('unmounted');
    };
  }, []);

  return (
    <Row gutter={16}>
      <Col span={4}>
        <div>
          <GlobalInsightSupplyMenuList menuItems={menuList} />
        </div>
      </Col>
      <Col span={20}>
        <GlobalInsightList
          globalInsightSupplyList={marketIntel}
          loading={loading}
          supplyPayload={supplyPayload}
          readonlyStats
        />
      </Col>
    </Row>
  );
}
