import { Badge } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as CandidateActions from '../../../Actions/CandidateActions';
import * as JobCandidatesTabActions from '../../../Actions/JobCandidatesTabActions';
import SmartAgentCandidateIcon from '../../../Icons/SmartAgentCandidateIcon';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer';
import { EllipsedText } from '../../../Utils/TextUtils';

const mapStateToProps = state => ({
  featureToggleList: getFeatureToggleList(state),
});
const mapDispatchToProps = {
  setManualSearchActiveSource: JobCandidatesTabActions.setManualSearchActiveSource,
  fetchSmartAgentCandidates: CandidateActions.fetchSmartAgentCandidates,
};

const SmartAgentCandidatesNotification = props => {
  const {
    notification,
    onClick,
    setJobCandidateActiveTab,
    history,
    setJobCandidateTabActiveSourceName,
    setManualSearchActiveSource,
    fetchSmartAgentCandidates,
    featureToggleList,
  } = props;

  const { Payload: payload, UnreadCount: unreadCount, UpdatedTime: updatedTime } = notification;
  const {
    JobId: jobId,
    FetchedCandidatesCount: fetchedCandidatesCount,
    TotalCandidatesCount: totalCandidatesCount,
    JobTitle: jobTitle,
  } = payload || {};

  const handleViewCandidate = async () => {
    if (!featureToggleList.SmartAgentCandidate.IsEnabled) return;
    await setJobCandidateActiveTab({ activeTab: 'sourced' });
    history.push(`/jobs/${jobId}/candidates?status=sourced`);
    await setJobCandidateTabActiveSourceName({ activeSourceName: 'smartAgentCandidates' });
    await setManualSearchActiveSource({ manualSearchActiveSource: 'smartAgentCandidates' });
    await fetchSmartAgentCandidates({ from: 0, size: 10, jobId });
    onClick(notification);
  };

  return (
    <div className="notification" role="presentation" onClick={handleViewCandidate}>
      <div className="icon">
        <SmartAgentCandidateIcon style={{ fontSize: '20px', color: '#13c26b' }} />
      </div>
      <div className="content">
        <div className="notification-title">
          <span className="bold">
            <EllipsedText text={jobTitle} maxTextLength={40} />
          </span>
          , {jobId}
        </div>
        <div className="message">
          <span className="bold"> {fetchedCandidatesCount} </span> of
          <span className="bold"> {totalCandidatesCount}</span>
          &nbsp;&nbsp;new candidates have been found by Smart Agent.
        </div>
        <div className="actions" />
        <div className="since">{moment.utc(updatedTime).local().fromNow()}</div>
      </div>
      <div className="is-viewed">
        <Badge dot={unreadCount > 0} />
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SmartAgentCandidatesNotification));
