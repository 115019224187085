import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import {
  isExactMatchSearch as isExactMatch,
  getManualSearchCandidatesCountPerBatch,
} from '../../Utils/ManualSearchUtils';
import * as jobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import * as manualSearchCandidateActions from '../../Actions/ManualSearchCandidateActions';
import CandidateListPagination from '../../Components/CandidateListPagination/CandidateListPagination';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getLatestCandidateSearchSuccessPage,
  getCandidateCount,
  getCandidateListType,
  getSupportedPageSizes,
  getSampleCandidateCount,
  getAppliedBucketScoringStatus,
} from '../../Reducers/CandidateReducer';
import { getConfig } from '../../Reducers/ConfigReducer';
import {
  getActiveSourceName,
  getCurrentPage,
  getFilterQueries,
  getManualSearchActiveSource,
  getPageSize,
  getActiveTab,
  getJobCandidateTabFrom,
} from '../../Reducers/JobCandidatesTabReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getManualSearchPayload } from '../../Reducers/ManualSearchReducer';
import {
  getAllTabFilteredFetchedCandidatesCount,
  getCandidatesBySource,
  getAllTabTotalFilteredCandidatesCount,
  getFetchedCandidatesCountBySource,
  getNextPageCandidatesFromBySource,
  getAllTabNextPageBySources,
  getManualSearchInitialCandidatesCountBySource,
  getIsAllTabFiltersApplied,
  getManualSearchAllTabDisplayedCandidateCount,
} from '../../Reducers/ManualSearchCandidateReducer';
import JobCandidatesTabMapper from './JobCandidatesTabMapper';
import { getSourceName } from '../../Utils/SourceUtils';
import * as CandidateActions from '../../Actions/CandidateActions';

const mapStateToProps = (state, props) => {
  const activeSourceName = getActiveSourceName(state);
  return {
    candidateListStatus: getApiStatus(state, 'candidateListStatus'),
    allTabCandidateFetchApiStatus: getApiStatus(state, 'allTabCandidatesFetchApi'),
    currPage: getCurrentPage(state),
    pageSize: getPageSize(state),
    totalCandidate: getCandidateCount(state),
    activeSourceName,
    sampleCandidateCount: getSampleCandidateCount(state),
    manualSearchActiveSource: getManualSearchActiveSource(state),
    manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
    allTabFetchedCandidatesCount: getAllTabFilteredFetchedCandidatesCount(state, { jobId: props.jobId }),
    candidatesBySource: getCandidatesBySource(state, { jobId: props.jobId }),
    filterQueries: getFilterQueries(state, activeSourceName),
    from: getJobCandidateTabFrom(state),
    activeTab: getActiveTab(state),
    previousSuccessfulCandidateFetchedPage: getLatestCandidateSearchSuccessPage(state, {
      jobId: props.jobId,
      source: activeSourceName,
    }),
    featureToggleList: getFeatureToggleList(state),
    allTabTotalCandidatesCount: getAllTabTotalFilteredCandidatesCount(state, { jobId: props.jobId }),
    allTabNextPageBySources: getAllTabNextPageBySources(state, { jobId: props.jobId }),
    candidateListType: getCandidateListType(state),
    supportedPageSizes: getSupportedPageSizes(state, props.candidateContext),
    appliedBucketScoringStatus: getAppliedBucketScoringStatus(state, { jobId: props.jobId }),
    pageMapping: getNextPageCandidatesFromBySource(state, {
      jobId: props.jobId,
      source: activeSourceName,
    }),
    manualSearchInitialCandidatesCountBySource: getManualSearchInitialCandidatesCountBySource(state, {
      source: activeSourceName,
      jobId: props.jobId,
    }),
    config: getConfig(state),
    isAllTabFiltersApplied: getIsAllTabFiltersApplied(state, { jobId: props.jobId }),
    allTabDisplayedCandidatesCount: getManualSearchAllTabDisplayedCandidateCount(state, { jobId: props.jobId }),
  };
};

const mapDispatchToProps = {
  setJobCandidateTabPagination: jobCandidatesTabActions.setJobCandidateTabPagination,
  setJobCandidateTabFrom: jobCandidatesTabActions.setJobCandidateTabFrom,
  setManualSearchActiveSource: jobCandidatesTabActions.setManualSearchActiveSource,
  fetchNextBatchManualSearchAllTabCandidates: manualSearchCandidateActions.fetchNextBatchManualSearchAllTabCandidates,
  fetchSmartAgentCandidates: CandidateActions.fetchSmartAgentCandidates,
};

const scrollToCandidatesTab = offsetTop =>
  window.scrollTo({
    top: offsetTop,
    left: 0,
    behavior: 'smooth',
  });

function JobCandidateListTabs(props) {
  const {
    candidateListStatus,
    currPage,
    pageSize,
    totalCandidate,
    currentPipelineFilter,
    jobId,
    candidateContext = 'job',
    currentJobDetails,
    status,
    showIntelBanners,
    atsJobCandidateListProps,
    connectionStatus,
    version,
    idealCandidateIntelPopupVisibility,
    defaultFilter,
    fetchAryaRecommendedCandidates,
    fetchManualSearchCandidates,
    setJobCandidateTabPagination,
    setJobCandidateTabFrom,
    from,
    sourcedCount,
    onStatusChange,
    scrollToRefineIntel,
    onCloseIdealCandidateIntelPopup,
    activeSourceName,
    manualSearchPayload = {},
    manualSearchActiveSource,
    history,
    publishedStatus,
    jobGuid,
    planName,
    allTabFetchedCandidatesCount,
    fetchNextBatchManualSearchAllTabCandidates,
    allTabCandidateFetchApiStatus,
    candidatesBySource,
    filterQueries,
    previousSuccessfulCandidateFetchedPage,
    featureToggleList,
    allTabTotalCandidatesCount,
    isNonInternalPortalForSegmentEnabled,
    openSipCallWindowsApp,
    candidateListType,
    supportedPageSizes,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isAryaRankedTab,
    smartRecruiterVersion,
    sampleCandidateCount,
    scoutingAgentCandidateCount,
    appliedBucketScoringStatus,
    pageMapping,
    allTabNextPageBySources,
    candidatesRecommendationStatus,
    manualSearchInitialCandidatesCountBySource,
    usersById,
    activeTab,
    isAllTabFiltersApplied,
    allTabDisplayedCandidatesCount,
    config,
    fetchSmartAgentCandidates,
    isPulseUser,
  } = props;

  const candidateListTabsRef = React.useRef();
  const [maintainLastPageKey, setMaintainLastPageKey] = React.useState({});

  const isSegmentCandidateSearchLimitFeatureEnabled = featureToggleList.SegmentCandidateSearchLimit.IsEnabled;
  const segmentCandidateSearchMaxLimit = 50000;
  const isExactMatchSearch =
    isExactMatch(manualSearchPayload) && status === 'Sourced' && activeSourceName !== 'AryaRecommended';

  const isNextButtonDisabled = maintainLastPageKey?.[activeSourceName]
    ? maintainLastPageKey[activeSourceName] - 1 === currPage
    : false;

  const onPageChangeSegment = async (page, size, callback) => {
    const updatedPage = totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
    const from = (updatedPage - 1) * size;
    if (candidateContext === 'segment' && activeTab !== 'sourced') {
      const updatedPage = totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
      const updatedFrom = (updatedPage - 1) * size;
      setJobCandidateTabFrom(updatedFrom);
      scrollToCandidatesTab(candidateListTabsRef.current?.offsetTop);
      setJobCandidateTabPagination({
        currPage: page,
        pageSize: size,
      });
      if (callback) {
        callback();
        const updatedPagination = { From: updatedFrom, Size: size };
        const candidatesFetchPayload = { ...defaultFilter, ...updatedPagination };
        await fetchAryaRecommendedCandidates({
          filter: candidatesFetchPayload,
          isDefaultFetch: true,
          showLoader: !callback,
        });
      }
    } else {
      if (isNextButtonDisabled && page > currPage) return;
      scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
      setJobCandidateTabPagination({
        currPage: page,
        pageSize: 10,
      });
      const updatedPagination = { From: activeSourceName === 'All' ? from : pageMapping[page] ?? 0, Size: 10 };
      const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
      const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
      const activeSource =
        manualSearchActiveSource ||
        manualSearchPayload.Sources.find(source => getSourceName(source) === activeSourceName);
      const updatedSources =
        candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
          ? manualSearchPayload.Sources.filter(x => x.Portal !== 'All')
          : [activeSource];
      const newManualSearchPayload = {
        ...manualSearchPayload,
        PortalQueries: { [activeSourceName]: updatedPortalQueries },
        Sources: updatedSources,
        ...updatedPagination,
        page,
      };
      fetchManualSearchCandidates({
        manualSearchPayload: newManualSearchPayload,
        setPrevCandidateCallback: callback,
      });
      if (activeSourceName === 'All' && allTabCandidateFetchApiStatus !== 'INPROGRESS') {
        const upperIndex = from + 10;
        if (allTabFetchedCandidatesCount - upperIndex < 30) {
          const sources = manualSearchPayload.Sources.filter(x => x.Portal !== 'All');
          const nonExhaustedSources = sources.filter(source => {
            const sourceName = getSourceName(source);
            const fetchedCount = allTabNextPageBySources[sourceName];
            return fetchedCount !== -1 && fetchedCount !== undefined;
          });
          if (nonExhaustedSources.length && (candidateContext !== 'segment' || isNonInternalPortalForSegmentEnabled)) {
            await fetchNextBatchManualSearchAllTabCandidates({
              manualCriteria: { ...manualSearchPayload, Sources: nonExhaustedSources },
              jobId,
              allowSetFetchCandidateApiStatus: false,
            });
            scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
          }
        }
      }
    }
  };

  const onPageChange = useCallback(
    async (page, size, callback) => {
      const updatedPage = totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
      const from = (updatedPage - 1) * size;
      const updatedPagination = { From: from, Size: size };
      scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
      setJobCandidateTabPagination({
        currPage: page,
        pageSize: size,
      });
      if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') {
        const candidatesFetchPayload = { ...defaultFilter, ...updatedPagination };
        await fetchAryaRecommendedCandidates({
          filter: candidatesFetchPayload,
          isDefaultFetch: true,
          showLoader: !callback,
        });
        if (callback) {
          callback();
        }
      } else if (activeSourceName === 'smartAgentCandidates') {
        fetchSmartAgentCandidates({ from, size, jobId });
      } else {
        const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
        const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
        const activeSource =
          manualSearchActiveSource ||
          manualSearchPayload.Sources.find(source => getSourceName(source) === activeSourceName);
        const updatedSources =
          candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
            ? manualSearchPayload.Sources.filter(x => x.Portal !== 'All')
            : [activeSource];
        const newManualSearchPayload = {
          ...manualSearchPayload,
          PortalQueries: { [activeSourceName]: updatedPortalQueries },
          Sources: updatedSources,
          ...updatedPagination,
          page: updatedPage,
        };
        fetchManualSearchCandidates({
          manualSearchPayload: newManualSearchPayload,
          setPrevCandidateCallback: callback,
        });
        if (activeSourceName === 'All' && allTabCandidateFetchApiStatus !== 'INPROGRESS' && !isAllTabFiltersApplied) {
          const upperIndex = from + size;
          const manualSearchCandidatesCountPerBatch = getManualSearchCandidatesCountPerBatch(
            featureToggleList.AdvancedSearchAggregationFilters.IsEnabled,
            candidateContext
          );
          if (allTabFetchedCandidatesCount - upperIndex < manualSearchCandidatesCountPerBatch + 5) {
            // !! This needs to be changed,
            // !! serve the details from cache, see if the required indexes are present or not, we cannot just depend on allTabFetchedCandidatesCount
            const sources = manualSearchPayload.Sources.filter(x => x.Portal !== 'All');
            const nonExhaustedSources = sources.filter(source => {
              const sourceName = getSourceName(source);
              const totalCount = candidatesBySource[sourceName]?.TotalCount;
              const fetchedCount = candidatesBySource[sourceName]?.FetchedCount;
              return fetchedCount < totalCount;
            });
            if (
              nonExhaustedSources.length &&
              (candidateContext !== 'segment' || isNonInternalPortalForSegmentEnabled)
            ) {
              await fetchNextBatchManualSearchAllTabCandidates({
                manualCriteria: { ...manualSearchPayload, Sources: nonExhaustedSources },
                jobId,
                allowSetFetchCandidateApiStatus: false,
              });

              scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
            }
          }
        }
      }
    },
    [
      totalCandidate,
      previousSuccessfulCandidateFetchedPage,
      activeSourceName,
      defaultFilter,
      manualSearchPayload,
      filterQueries,
      manualSearchActiveSource,
      allTabCandidateFetchApiStatus,
      allTabFetchedCandidatesCount,
      candidateContext,
      candidatesBySource,
      isNonInternalPortalForSegmentEnabled,
      fetchNextBatchManualSearchAllTabCandidates,
      fetchManualSearchCandidates,
      fetchAryaRecommendedCandidates,
      setJobCandidateTabPagination,
    ]
  );

  const getUpdatedPage = ({ page }) => {
    if (activeSourceName === 'All') return 1;
    return totalCandidate < (page - 1) * 10 ? previousSuccessfulCandidateFetchedPage : page;
  };

  function calculateSmartAgentCandidateNewPage(currentPage, currentSize, newSize) {
    const totalItems = (currentPage - 1) * currentSize;
    return Math.floor(totalItems / newSize) + 1;
  }

  const onShowSizeChangeCallBack = useCallback(
    ({ page, size }) => {
      const updatedPage =
        activeSourceName === 'smartAgentCandidates'
          ? calculateSmartAgentCandidateNewPage(currPage, pageSize, size)
          : getUpdatedPage({ page });
      const from = (updatedPage - 1) * size;
      const updatedPagination = { From: from, Size: size };
      if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') {
        const candidatesFetchPayload = { ...defaultFilter, ...updatedPagination };
        fetchAryaRecommendedCandidates({ filter: candidatesFetchPayload });
      } else if (activeSourceName === 'smartAgentCandidates') {
        fetchSmartAgentCandidates({ from, size, jobId });
      } else {
        const activeSource =
          manualSearchActiveSource ||
          manualSearchPayload.Sources.find(source => getSourceName(source) === activeSourceName);
        const updatedSources =
          candidateContext === 'segment' && !isNonInternalPortalForSegmentEnabled
            ? manualSearchPayload.Sources.filter(x => x.Portal !== 'All')
            : [activeSource];
        const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
        const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
        const newManualSearchPayload = {
          ...manualSearchPayload,
          PortalQueries: { [activeSourceName]: updatedPortalQueries },
          Sources: updatedSources,
          ...updatedPagination,
          page: updatedPage,
        };
        fetchManualSearchCandidates({ manualSearchPayload: newManualSearchPayload });
      }
      setJobCandidateTabPagination({
        currPage: updatedPage,
        pageSize: size,
      });
      scrollToCandidatesTab(candidateListTabsRef.current.offsetTop);
    },
    [
      totalCandidate,
      previousSuccessfulCandidateFetchedPage,
      activeSourceName,
      defaultFilter,
      manualSearchActiveSource,
      manualSearchPayload,
      filterQueries,
      isNonInternalPortalForSegmentEnabled,
      fetchManualSearchCandidates,
      fetchAryaRecommendedCandidates,
      setJobCandidateTabPagination,
    ]
  );

  const getTotalCandidatesCount = () => {
    const subSegmentRendetState = localStorage.getItem('subsegmentState');
    if (activeTab === 'subsegment' && subSegmentRendetState === 'listView') return 0;
    if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') return totalCandidate;
    if (isSegmentCandidateSearchLimitFeatureEnabled && candidateContext === 'segment')
      return Math.min(totalCandidate, segmentCandidateSearchMaxLimit);
    if (candidateContext === 'segment') return Math.min(totalCandidate, 1000);
    if (activeSourceName === 'All')
      return Math.min(allTabFetchedCandidatesCount, config?.MaxCandidateCountInAdvanceSearch);
    if (activeSourceName === 'smartAgentCandidates') return Math.min(totalCandidate, config?.SmartAgentCandidatesCount);
    return Math.min(totalCandidate, config?.MaxCandidateCountInAdvanceSearch);
  };

  const getTotalCandidatesDisplayCount = () => {
    if (activeSourceName === 'AryaRecommended' || activeSourceName === 'scoutingAgent') return totalCandidate;
    if (isSegmentCandidateSearchLimitFeatureEnabled && candidateContext === 'segment')
      return Math.min(totalCandidate, segmentCandidateSearchMaxLimit);
    if (activeSourceName === 'All')
      return Math.min(allTabTotalCandidatesCount, config?.MaxCandidateCountInAdvanceSearch);
    if (activeSourceName === 'smartAgentCandidates') return Math.min(totalCandidate, config?.SmartAgentCandidatesCount);
    return Math.min(totalCandidate, config?.MaxCandidateCountInAdvanceSearch);
  };

  const totalCandidatesCount = getTotalCandidatesCount();

  React.useEffect(() => {
    if (Object.values(pageMapping).pop() === -1) {
      const targetValue = -1;
      const keyWithTargetValue = Object.keys(pageMapping).find(key => pageMapping[key] === targetValue);
      setMaintainLastPageKey(prevState => ({ ...prevState, [activeSourceName]: keyWithTargetValue }));
    }
  }, [pageMapping]);

  const totalCandidatesDisplayCount = getTotalCandidatesDisplayCount();
  const getManualSearchPayloadSource = () => {
    return manualSearchPayload?.Sources?.find(source => getSourceName(source) === activeSourceName);
  };

  const generateManualSearchPayload = redirectToPage => {
    const from = ((redirectToPage || currPage) - 1) * pageSize;
    const updatedPagination = { From: from, Size: !isExactMatchSearch ? pageSize : 10 };
    const currentPortalQueries = manualSearchPayload.PortalQueries?.[activeSourceName];
    const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
    return { updatedPagination, updatedPortalQueries };
  };

  const fetchManualSearchCandidateList = redirectToPage => {
    const sources = getManualSearchPayloadSource();
    const { updatedPagination, updatedPortalQueries } = generateManualSearchPayload(redirectToPage);
    const newManualSearchPayload = {
      ...manualSearchPayload,
      PortalQueries: { [activeSourceName]: updatedPortalQueries },
      Sources: [sources],
      ...updatedPagination,
    };
    const isAllTabCandidateFetchRequestAllowed = activeSourceName === 'All';
    fetchManualSearchCandidates({
      manualSearchPayload: newManualSearchPayload,
      isAllTabCandidateFetchRequestAllowed,
    });
  };

  const isAllTabCandidateFetchAllowed = () => {
    return (
      (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled) &&
      activeSourceName === 'All' &&
      allTabCandidateFetchApiStatus === 'COMPLETED'
    );
  };
  const nonPortalSourceNames = ['AryaRecommended', 'All', 'scoutingAgent', 'smartAgentCandidates'];
  const isNonPortalSourceActive = nonPortalSourceNames.includes(activeSourceName);

  React.useEffect(() => {
    if (!isNonPortalSourceActive && (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled)) {
      fetchManualSearchCandidateList();
    }
  }, [activeSourceName, manualSearchInitialCandidatesCountBySource]);

  React.useEffect(() => {
    if (isAllTabCandidateFetchAllowed()) {
      fetchManualSearchCandidateList();
    }
  }, [allTabCandidateFetchApiStatus, allTabDisplayedCandidatesCount]);

  const paginationId =
    candidateContext === 'segment' && candidateListType === 'SourcedCandidateList' ? 'segment' : 'job';

  const shouldRemovePagination =
    (candidateContext === 'segment' && (status === 'Shortlisted' || status === 'Rejected')) || isExactMatchSearch;
  const totalCount =
    candidateContext === 'segment' && activeTab !== 'sourced' ? sampleCandidateCount : totalCandidatesDisplayCount;
  return (
    <div ref={candidateListTabsRef}>
      <JobCandidatesTabMapper
        onPageChange={shouldRemovePagination ? onPageChangeSegment : onPageChange}
        currentPipelineFilter={currentPipelineFilter}
        jobId={jobId}
        candidateContext={candidateContext}
        sourcedCount={sourcedCount}
        scoutingAgentCandidateCount={scoutingAgentCandidateCount}
        currentJobDetails={currentJobDetails}
        usersById={usersById}
        status={status}
        showIntelBanners={showIntelBanners}
        atsJobCandidateListProps={atsJobCandidateListProps}
        connectionStatus={connectionStatus}
        version={version}
        defaultFilter={defaultFilter}
        fetchAryaRecommendedCandidates={fetchAryaRecommendedCandidates}
        fetchManualSearchCandidates={fetchManualSearchCandidates}
        manualSearchActiveSource={manualSearchActiveSource}
        onStatusChange={onStatusChange}
        scrollToRefineIntel={scrollToRefineIntel}
        onCloseIdealCandidateIntelPopup={onCloseIdealCandidateIntelPopup}
        history={history}
        publishedStatus={publishedStatus}
        jobGuid={jobGuid}
        planName={planName}
        totalCandidate={totalCandidate}
        filterQueries={filterQueries}
        isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
        openSipCallWindowsApp={openSipCallWindowsApp}
        openSegmentAtsView={openSegmentAtsView}
        openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        onClickAddJobCallBack={onClickAddJobCallBack}
        isAryaRankedTab={isAryaRankedTab}
        smartRecruiterVersion={smartRecruiterVersion}
        from={from}
        setFrom={setJobCandidateTabFrom}
        sampleCandidateCount={sampleCandidateCount}
        appliedBucketScoringStatus={appliedBucketScoringStatus}
        isExactMatchSearch={isExactMatchSearch}
        candidatesRecommendationStatus={candidatesRecommendationStatus}
        fetchManualSearchCandidateList={fetchManualSearchCandidateList}
        isPulseUser={isPulseUser}
      />
      {candidateContext === 'job' || totalCandidatesCount ? (
        <CandidateListPagination
          totalCandidate={shouldRemovePagination ? totalCount : totalCandidatesCount || 0}
          totalCandidatesDisplayCount={totalCandidatesDisplayCount}
          onChange={shouldRemovePagination ? onPageChangeSegment : onPageChange}
          onShowSizeChangeCallBack={onShowSizeChangeCallBack}
          disabled={candidateListStatus === 'INPROGRESS'}
          pageSize={pageSize}
          status={status}
          currentPage={currPage}
          inlineStyles={idealCandidateIntelPopupVisibility ? { paddingBottom: '120px' } : null}
          onlyPrevNext={
            activeSourceName === 'All' && (candidateContext === 'job' || isNonInternalPortalForSegmentEnabled)
          }
          shouldRemovePagination={shouldRemovePagination}
          candidateContext={candidateContext}
          previousSuccessfulCandidateFetchedPage={previousSuccessfulCandidateFetchedPage}
          pageSizeOptions={supportedPageSizes.map(x => x.toString())}
          paginationId={paginationId}
          isNextButtonDisabled={isNextButtonDisabled}
          showRange={!isExactMatchSearch}
          isExactMatchSearch={isExactMatchSearch}
        />
      ) : null}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCandidateListTabs);
export { JobCandidateListTabs as JobCandidateListTabsWithoutStore };
